<template>
    <div>
        <v-row class="py-0" align="center" justify="center">
            <!-- <v-col class="py-0">
                <v-autocomplete :items="items.sellers" label="Solicitante *" v-model="values.seller_id" :rules="rules.seller_id" :loading="loadingSellers" append-icon="mdi-chevron-down" :disabled="edition"></v-autocomplete>
            </v-col> -->
            <!-- v-col class="py-0">
                <v-autocomplete :items="items.buildings" label="Obra *" v-model="values.building_id" :rules="rules.building_id" :loading="loadingBuildings" append-icon="mdi-chevron-down" :disabled="edition"></v-autocomplete>
            </v-col -->
            <v-col class="py-0" cols="12" md="6">
                <datepicker-dialog :loading="loadingDate" :disabled="true" :picker="dateProps" :dateVal="values.date" @setDate="v => values.date = v" :rules="rules.date"></datepicker-dialog>
            </v-col>
            <v-col class="py-0" cols="12" md="6">
                <v-autocomplete :items="items.saleOrders" label="Orden de trabajo/Proyecto *" v-model="values.sale_order_id" :rules="rules.sale_order_id" :loading="loadingSaleOrders" append-icon="mdi-chevron-down"></v-autocomplete>
            </v-col>
        </v-row>
        <v-row class="py-0" align="center" justify="center">
           
             <v-col class="py-0">
                <v-autocomplete :items="items.businesses" label="Empresa *" v-model="values.business" :rules="rules.required" :loading="loadingBusiness" append-icon="mdi-chevron-down"></v-autocomplete>
            </v-col>
        </v-row>

        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-textarea label="Observaciones" v-model="values.comments" :rules="rules.comments"></v-textarea>
            </v-col>
        </v-row>


        <v-row>
            <v-col>
                <div class="text-uppercase font-weight-bold primary--text text-h5">
                    Catálogo
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="py-0">
                <v-autocomplete :items="items.products" label="Concepto *" v-model="product_id" :loading="loadingProducts" append-icon="mdi-chevron-down"></v-autocomplete>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="py-0 text-right">
                <v-btn class="py-0 full-width-btn" color="primary" text tile @click="addProducts">Agregar</v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card outlined>
                    <v-card-text>
                        <v-data-table :headers="tableProps.headers" :items="values.concepts" disable-sort disable-pagination> <!-- :loading="table_loading" -->
                            <template v-slot:item.quantity="props">
                                <v-edit-dialog :return-value.sync="props.item.quantity">
                                    <!--@cancel="cancelEdition" @open="open" @close="close" -->
                                    <div class="primary--text" style="font-weight: 700;">{{ props.item.quantity }}</div>
                                    <template v-slot:input>
                                        <v-text-field v-model="props.item.quantity" label="Cantidad"></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </template>

                            <template v-slot:item.actions="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn x-small class="mx-0 " icon @click="removeProduct(item.concept_id)">
                                            <v-icon color="secondary" v-on="on">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Borrar</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'requisition-form',
    props: ['rules', 'values', 'edition'],
    computed: {
        supplier_id(){
            return this.values.supplier_id
        }
    },
    watch: {
        [`values.business`](v){
            if(this.items.businesses.length == 0)
                return;

            this.values.business_text = "";
            // console.log(v, "changeeee")
            const found = this.items.businesses.find((item) => item.value == v);
            if(found != undefined)
                this.values.business_text = found.text;
            
        }

       
    },
    data(){
        return {
            product_id: '',
            loadingSellers: false,
            loadingBuildings: false,
            loadingSaleOrders: false,
            loadingSuppliers: false,
            loadingProducts: false,
            loadingDate:false,
            loadingBusiness:false,
            products: [],
            items: {
                // sellers: [
                //     { text: 'Roberto Mercado Ugalde', value: 'Roberto Mercado Ugalde' },
                //     { text: 'Roberto Guadiana', value: 'Roberto Guadiana' },
                //     { text: 'Eduardo López', value: 'Eduardo López' },
                //     { text: 'Mario Mercado Ugalde', value: 'Mario Mercado Ugalde' },
                //     { text: 'Francisco Aguiñaga', value: 'Francisco Aguiñaga' },
                //     { text: 'Fernando Ramos', value: 'Fernando Ramos' },
                //     { text: 'Administración', value: 'Administración' },
                //     { text: 'Mónica Páez', value: 'Mónica Páez' },
                //     { text: 'Salma Vázquez', value: 'Salma Vázquez' },
                //     { text: 'Sergio Mercado Ugalde', value: 'Sergio Mercado Ugalde' },
                //     { text: 'José Serrano', value: 'José Serrano' },
                //     { text: 'Recursos Humanos', value: 'Recursos Humanos' },
                //     { text: 'Jorge Mercado Ugalde', value: 'Jorge Mercado Ugalde' },
                //     { text: 'Aidee Garay', value: 'Aidee Garay' },
                //     { text: 'Gustavo Reinteria', value: 'Gustavo Reinteria' },
                // ],
                //buildings: [],
                saleOrders: [],
                suppliers: [],
                products: [],
                businesses: []
            },
            dateProps: {
                visible: false,
                date: '',
                width: '300px',
                label: 'Fecha',
                icon: 'mdi-calendar',
                btn: {
                    cancelColor: '',
                    okColor: 'primary'
                }
            },
            tableProps: {
                headers: [
                    {
                        text: 'Concepto',
                        align: 'left',
                        value: 'name',
                        width: '40%',
                        class: 'primary--text uppercase--text'
                    },
                    {
                        text: 'Unidad',
                        align: 'center',
                        value: 'unit',
                        width: '5%',
                        class: 'primary--text uppercase--text'
                    },
                    {
                        text: 'Cantidad',
                        align: 'center',
                        value: 'quantity',
                        width: '5%',
                        class: 'primary--text uppercase--text'
                    },
                   
                    {
                        text: '',
                        align: 'right',
                        value: 'actions',
                        width: '5%',
                        class: 'primary--text uppercase--text'
                    },
                ],
            }
        }
    },
    mounted(){
        this.index();
        this.getProducts();
        this.getBusiness();

        console.log(this.values, "form")
    },
    methods: {
        index(){
            // this.loadingBuildings = true
            // this.$http.get(this.$store.state.apiRoute+'/getBuildings')
            // .then((response) => { this.items.buildings = response.data })
            // .catch((error) => { })
            // .finally(() => { this.loadingBuildings = false })
            
            this.loadingSaleOrders = true
            this.$http.get(this.$store.state.apiRoute+'/getSaleOrders')
            .then((response) => { this.items.saleOrders = response.data })
            .catch(() => { })
            .finally(() => { this.loadingSaleOrders = false })
            
            // this.loadingSuppliers = true
            // this.$http.get(this.$store.state.apiRoute+'/getSuppliers')
            // .then((response) => { this.items.suppliers = response.data })
            // .catch(() => { })
            // .finally(() => { this.loadingSuppliers = false })

           if(this.values.date == "" && !this.edition){
                console.log("DATE APIII")
                this.loadingDate = true
                this.$http.get(this.$store.state.apiRoute+'/getTime')
                .then((response) => { this.values.date = response.data.date })
                .catch(() => { })
                .finally(() => { this.loadingDate = false })
           }
        },
        getBusiness()
        {
            this.loadingBusiness = true;
            this.items.businesses = [];
            this.$http.get(this.$store.state.flujoRoute+'/external/business')
            .then((response) => {
                console.log(response.data)
                for (let i = 0; i < response.data.length; i++) {
                    const element = response.data[i];
                    if(this.$store.state.userData.business.includes(element.value)){
                        this.items.businesses.push(element);
                    }
                }
                console.log(this.items.businesses)

                //add the current business of the requisition
                if(this.edition && this.values.business != ""){
                    let found = this.items.businesses.find((actual) => actual.value == this.values.business);
                    console.log(found, "found")
                    if(found == undefined){
                        let addActual = response.data.find((newActual) => newActual.value == this.values.business);
                        console.log(addActual, "acrual", this.values.business)
                        if(addActual != undefined)
                            this.items.businesses.push(addActual);
                    }
                }

                if(this.items.businesses.length == 1 && !this.edition){
                    this.values.business = this.items.businesses[0].value
                }
            })
            .finally(() => {this.loadingBusiness = false;});
        },
        getProducts(){
            this.loadingProducts = true
            this.$http.get(this.$store.state.apiRoute+'/getConcepts')
            .then((response) => { this.items.products = response.data })
            .catch(() => { })
            .finally(() => { this.loadingProducts = false })
        },
        getProductName(id){
            var product = this.items.products.find(x => x.value === id)
            return product.text.split('|').join(' | ');
        },
        getProductVariant(id){
            let name = this.items.products.find((item) => item.value == id).text;
            return name;
        },
        getProductUnit(id){
            let unit = this.items.products.find((item) => item.value == id).unit;
            return unit;
        },
        addProducts(){
            if(this.product_id != ''){
                var validation = this.values.concepts.findIndex(x => x.concept_id === this.product_id)
                if(validation < 0){
                    // var name = this.items.products.find(x => x.value === this.product_id)
                    // var product_variant = this.getProductName(this.product_id)
                    var product_t = {
                        id: "",
                        name: this.getProductVariant(this.product_id),
                        concept_id: this.product_id,
                        quantity: 1,
                        unit: this.getProductUnit(this.product_id),
                        amount: 0,
                        subtotal: '0',
                        actions: ''
                    }
                    this.values.concepts.push(product_t)
                }
            }
        },
        removeProduct(id){
            console.log(id)
            let found = this.values.concepts.findIndex(element => element.concept_id == id);
            this.values.concepts.splice(found, 1)
        },
        save(){
            this.$emit('save')
        }
    },
    
}
</script>

<style>

</style>