<template>
	<v-navigation-drawer v-model="$store.state.menu" app color="primary" :src="image(`${this.sidebgimage.folder}/${this.sidebgimage.name}`,`${this.sidebgimage.extension}`)">
		<v-list-item>
			<v-list-item-content class='mt-8 mb-8'>
				<v-img :src="image(`${this.logo.folder}/${this.logo.name}`,`${this.logo.extension}`)" contain height="40"></v-img>
			</v-list-item-content>
		</v-list-item>

		<v-divider></v-divider>

		<v-list dense tile flat dark>
			<div v-for="(modulo, i) in menu" :key="i">
				<v-list-group  v-if="modulo.submenu.length > 0" active-class="yellow-font" v-permission:any="modulo.permissions??'all'">
					<template v-slot:activator>
						<v-list-item-title>{{ modulo.title }}</v-list-item-title>
					</template>
					
					<v-list-item v-for="(submodulo, j) in modulo.submenu" :key="j" :to="submodulo.link" :inactive="submodulo.link === null" active-class="white--text border-yellow" v-permission:any="submodulo.permissions??'all'">
						<!-- <v-list-item-icon><v-icon>{{ submodulo.icon }}</v-icon></v-list-item-icon> -->
						<v-list-item-title>{{ submodulo.title }}</v-list-item-title>						
					</v-list-item>
				</v-list-group>

				<v-list-item v-permission:any="modulo.permissions??'all'" :to="modulo.link" v-else :inactive="modulo.link === null" active-class="white--text border-yellow">
					<!-- <v-list-item-icon><v-icon>{{ modulo.icon }}</v-icon></v-list-item-icon> -->
					<v-list-item-title>{{ modulo.title }}</v-list-item-title>
				</v-list-item>
			</div>
		</v-list>
	</v-navigation-drawer>
</template>

<script>
	import {bgImage, image} from "@/mixins/images.js"
	export default {
		name: 'Sidemenu',
		mixins:[bgImage,image],		
		data () {
			return {
				menu: [
					// {
					// 	title: 'DASHBOARD',
					// 	name: 'Dashboard',
					// 	icon: 'mdi-view-dashboard',
					// 	submenu: [],
					// 	link: '/dashboard'
					// },
					{
						title: 'OBRAS',
						name: 'Obras',
						icon: 'mdi-account-hard-hat',
						submenu: [],
						link: '/obras',
                        permissions: 'buildings.show'
					},
					{
						title: 'COMPRAS',
						name: 'Compras',
						icon: 'mdi-cart',
                        permissions: 'suppliers.show|requisitions.show|purchaseOrders.show|concepts.show',
						submenu: [
                            { title: 'PROVEEDORES', icon: 'mdi-star-face', name: 'Proveedores', link: '/proveedores', permissions: 'suppliers.show'},
							{ title: 'CATÁLOGO', icon: 'mdi-star-face', name: 'Conceptos', link: '/conceptos', permissions: 'concepts.show'},
							// { title: 'MATERIAS PRIMAS', icon: 'mdi-star-face', name: 'MateriasPrimas', link: '/materias-primas', permissions: 'products.show'},
							{ title: 'REQUISICIONES', icon: 'mdi-star-face', name: 'Requisiciones', link: '/requisiciones' ,permissions: 'requisitions.show'},
							{ title: 'ÓRDENES DE COMPRA', icon: 'mdi-star-face', name: 'OrdenesdeCompra', link: '/ordenes-de-compra' ,permissions: 'purchaseOrders.show'},
						]
					},
					{
						title: 'VENTAS',
						name: 'Ventas',
						icon: 'mdi-briefcase-account',
                        permissions: 'clients.show|quotes.show|saleOrders.show|bigProducts.show',
						submenu: [
							{ title: 'CLIENTES', icon: 'mdi-star-face', name: 'Clientes', link: '/clientes', permissions: 'clients.show'},
                            { title: 'PRODUCTOS', icon: 'mdi-star-face', name: 'Productos', link: '/productos' ,permissions: 'bigProducts.show'},
							// { title: 'VENDEDORES', icon: 'mdi-star-face', name: 'Vendedores', link: '/vendedores', permissions: 'sellers.show'},
							{ title: 'COTIZACIONES', icon: 'mdi-star-face', name: 'Cotizaciones', link: '/cotizaciones', permissions: 'quotes.show'},
							{ title: 'ORDENES DE TRABAJO', icon: 'mdi-star-face', name: 'OrdenesDeVenta', link: '/ordenes-de-trabajo', permissions: 'saleOrders.show'},
						]
					},
					{
						title: 'CONTABILIDAD',
						name: 'Contabilidad',
						icon: 'mdi-kabaddi',
                        permissions: 'banks.show',
						submenu: [
							{ title: 'BANCOS', icon: 'mdi-star-face', name: 'Bancos', link: '/bancos', permissions: 'banks.show'},
							// { title: 'CUENTAS X COBRAR', icon: 'mdi-star-face', name: 'CuentasPorCobrar', link: '/cuentas-por-cobrar'},
							// { title: 'CUENTAS X PAGAR', icon: 'mdi-star-face', name: 'CuentasPorCobrar', link: '/cuentas-por-pagar'},
						]
					},
					{
						title: 'CATÁLOGOS',
						name: 'Catálogos',
						icon: 'mdi-book-open-blank-variant',
                        permissions: 'catalog_bank.show|catalog_unit.show',
						submenu: [
							{ title: 'BANCOS', icon: 'mdi-bank', name: 'Bancos', link: '/catalogos/bancos', permissions: 'catalog_bank.show'},
							{ title: 'UNIDADES', icon: 'mdi-set-square', name: 'Unidades', link: '/catalogos/unidades', permissions: 'catalog_unit.show'},
						]
					},
                    {
						title: 'Accesos',
						name: 'Accesos',
						icon: 'mdi-kabaddi',
                        permissions: 'users.show|roles.show',
						submenu: [
							{ title: 'USUARIOS', icon: 'mdi-star-face', name: 'Usuarios', link: '/usuarios', permissions: 'users.show'},
							{ title: 'ROLES', icon: 'mdi-star-face', name: 'Roles', link: '/roles', permissions: 'roles.show'},
						]
					},
				/*	{
						title: 'USUARIOS',
						name: 'Usuarios',
						icon: 'mdi-account-hard-hat',
						submenu: [],
						link: '/usuarios'
					},	*/
				],
				right: null,
				sidebgimage:{
					folder:"dashboard/system",
					name:"sidebar",
					extension:"png"
				},
				logo:{
					folder:"dashboard/system",
					name:"logo_grupo_merca_white",
					extension:"svg"					
				}
			}
		},
		methods: {
			
		}
	};
</script>
<style lang="css">
	.v-list-group__items .v-list-item {
		padding-left: 30px;
	}

	.border-orange{
		border: 1px solid #cb6120;
		border-left-width: 6px;
	}



	.v-list-group__items .v-list-item.border-yellow {
		padding-left: 24px !important;
	}

	.v-list-item.border-yellow{
		padding-left: 10px !important;
	}

	.v-list--dense .v-list-item .v-list-item__icon{
		margin-top: 7px;
		margin-bottom: 7px;
	}
</style>