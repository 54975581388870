import Vue from 'vue';
import store from "../store";
import Permissions from '../views/Modules/Roles/permission';



export default class VueGatesPlugin{
    static init()
    {   
        
        // console.log(Vue.prototype.$gates.getRoles())
        // if(Vue.prototype.$gates.getRoles().length != 0)
        //     return;
        Vue.prototype.$gates.setRoles([]);
        Vue.prototype.$gates.setPermissions([]);
        let user = store.state.userData;
        Vue.prototype.$gates.setRoles([user.role.name]);
        // console.log(Vue.prototype.$gates.getRoles(), store.state.userData);
        let actions = ['all'];
        for (const property in user.role.permissions) {
            if(property == 'root' && user.role.permissions[property] == true ){
                for (const property in Permissions) {
                    if(property != 'root'){
                        actions.push(`${property}.show`);
                        actions.push(`${property}.create`);
                        actions.push(`${property}.update`);
                        actions.push(`${property}.delete`);
                        actions.push(`${property}.cancel`);
                        actions.push(`${property}.save`);
                    }
                    
                }
                break;
            }
            if(!(user.role.permissions[property] instanceof Array) && property != 'root'){
                for(const action in user.role.permissions[property]){
                    // console.log(action, user.role.permissions[property][action])
                    if(user.role.permissions[property][action]){
                        actions.push(`${property}.${action}`);
                    }
                }
            }
        }
        Vue.prototype.$gates.setPermissions(actions);
        // console.log(Vue.prototype.$gates.getPermissions());
    }

    static clear()
    {
        
    }




    static checkRoutePermission(routeData)
    {
        // console.log(store.state.userData);
        // if(Vue.prototype.$gates.getPermissions().length == 0)
        //     return true;
        const permissionsReq = (routeData.meta?.permissions?.split("|"))??['all']
        console.log(permissionsReq)
        for (let i = 0; i < permissionsReq.length; i++) {
            if(Vue.prototype.$gates.hasAllPermissions(permissionsReq[i]))
                return true;
            
        }
        
        return false;


        // console.log(Vue.prototype.$gates.hasAllPermissions(routeData.meta.permissions??'all'), routeData)
        // if(Vue.prototype.$gates.hasAllPermissions(routeData.meta.permissions??'all'))
        //     return true;
            
        // return false;
    }
   
}

