<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center">
                    <v-col class="text-center">
                        <div class="text-uppercase font-weight-bold primary--text text-h5">
                            Editar orden de compra
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center" v-if="!loading">
                    <v-col cols="12" md="8">
                        <v-row class="py-0" align="center" justify="center">
                            <v-col>
                                <div v-for="(detail, i) in detailsItems" :key="i">
                                    <span class="font-weight-bold text-h6 primary--text">{{ detail.concepto }}: </span> <span class="font-weight-regular text-h6"> {{ detail.valor }} </span> <br>
                                </div>
                            </v-col>
                        </v-row>
                        <v-form ref="form" @submit.prevent="save">
                            <ordenes-form :values="form" :rules="rules" :edit="true"></ordenes-form>
                        </v-form>
                    </v-col>
                </v-row>

            </div>
			<v-row justify="center" align="end">
				<v-col cols="12" md="8" class="text-right">
					<v-btn class="full-width-btn" tile text color="primary" :to="{ name: 'OrdenesDeCompra' }">Cancelar</v-btn>
					<v-btn tile color="secondary" class="ml-md-2 ml-0 mt-md-0 mt-3 full-width-btn text-base-color" @click="save">Guardar</v-btn>
				</v-col>
			</v-row>
            <snackbar :props="snackbarProps"></snackbar>
        </template>
    </contentCard>
</template>
<script>
import OrdenForm from "./Form.vue";

export default {
    components: {
        'ordenes-form': OrdenForm,
    },
	data(){
		return {
            id: this.$route.params.id,
            loading: true,
            detailsItems: [
                { concepto: 'Obra', index: 'building', valor: null },
                { concepto: 'Empresa', index: 'business_text', valor: null },
                // { concepto: 'Proveedor', index: 'supplier', valor: null },
                { concepto: 'Fecha', index: 'date', valor: null },
                // { concepto: 'Tipo de materia a solicitar', index: 'date', valor: null },
            ],
            form: {
                building_id: '',
                supplier_id: '',
                seller_id: '',
                sale_order_id: '',
                requisition_id: '',
                products: []
            },
            rules: {
                client_id: [
                    v => !!v || 'Cliente es requerido'
                ],
                building_id: [
                    v => !!v || 'Obra es requerido'
                ],
                date: [
                    v => !!v || 'Fecha es requerido'
                ],
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        this.index()
    },
	methods: {
		validate () {
			return this.$refs.form.validate()
        },
        index(){
            this.loading = true;
            this.$http.get(this.$store.state.apiRoute+'/purchaseOrders/'+this.id+'/edit')
            .then((response) => {
                this.detailsItems.forEach((x) => {
                    x.valor = response.data.data[x.index] === null ? '' : response.data.data[x.index]
                })

                this.form = {
                    // building_id: response.data.data.building_id,
                    supplier_id: response.data.data.supplier_id,
                    // seller_id: response.data.data.seller_id,
                    // sale_order_id: response.data.data.sale_order_id,
                    requisition_id: response.data.data.requisition_id,
                    concepts: response.data?.concepts.map((x) => {
                        return {
                            concept_id: x.concept_id,
                            name: x.concept,
                            quantity: x.quantity,
                            amount: x.amount,
                            subtotal: parseFloat(""+x.total).toFixed(2),
                            unit: x.unit,
                            status: x.status,
                            actions: ''
                        }
                    })
                };

                console.log(this.form);
            })
            .catch(error => {
                error
            })
            .finally(() => {
                this.$store.state.overlay = false;
                this.loading = false;
            })
        },
        save(){
            this.$store.state.overlay = true

            if(this.validate()){      
                var form = this.form
                 form.concepts = this.form.concepts.map((x) => {
                    return {
                        concept_id: x.concept_id,
                        quantity: x.quantity,
                        amount: x.amount
                    }
                })
                
                this.$http.put(this.$store.state.apiRoute+'/purchaseOrders/'+this.id+'/update', form)
                .then((response) => {
                    response.data

                    this.$store.state.globalSnackbarProps.visible = true
                    this.$store.state.globalSnackbarProps.text = 'Se ha registrado actualizada la orden de compra <b>'+this.id+'</b>'
                    this.$router.push({ name: 'OrdenesDeCompra' })
                })
                .catch((error) => {
                    var errors = []
                    switch(error.response.status){
                        case 422: 
                            Object.keys(error.response.data.errors).forEach((x) => {
                                error.response.data.errors[x].forEach((y) => {
                                    errors.push(y)
                                })
                            })
                        break;
                        case 500: 
                            errors.push('Error en el servidor. Intente de nuevo.')
                        break;
                        case 404: 
                            errors.push('No encontrado.')
                        break;
                    }

                    this.snackbarProps = {
                        visible: true,
                        color: 'error',
                        timeout: 10000,
                        text: errors.join('.<br>'),
                    }
                })
                .finally(() => {
                    this.$store.state.overlay = false
                })
            }else{
                this.snackbarProps = {
                    visible: true,
                    color: 'error',
                    timeout: 2000,
                    text: 'Formulario incompleto',
                }

                this.$store.state.overlay = false
            }
            // eslint-disable-next-line no-console
        }
    },
}
</script>

<style>

</style>