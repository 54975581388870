<template>
    <div>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-text-field label="Nombre *" v-model="values.name" :rules="rules.name"></v-text-field>
            </v-col>
        </v-row>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-text-field label="RFC" v-model="values.rfc" counter="13" :rules="rules.rfc"></v-text-field>
            </v-col>
        </v-row>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-text-field label="Razón Social" v-model="values.business_name " :rules="rules.business_name"></v-text-field>
            </v-col>
        </v-row>

        <country-autocomplete :state="true" :city="true" :presetvalue="values.address_code" @getValue="v => values.address_code = v" :required="[/*'country', 'state', 'city'*/]"></country-autocomplete>

        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-text-field label="Colonia " v-model="values.neighborhood " :rules="rules.neighborhood"></v-text-field>
            </v-col>
        </v-row>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-text-field label="Calle " v-model="values.street " :rules="rules.street"></v-text-field>
            </v-col>
        </v-row>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0" cols="12" md="6">
                <v-text-field label="No. Ext. " v-model="values.ext_number " :rules="rules.ext_number"></v-text-field>
            </v-col>
            <v-col class="py-0">
                <v-text-field label="No. Int. " v-model="values.int_number " :rules="rules.int_number"></v-text-field>
            </v-col>
        </v-row>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-text-field label="Contacto *" v-model="values.contact " :rules="rules.contact"></v-text-field>
            </v-col>
        </v-row>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-text-field label="Teléfono" counter="10" v-model="values.phone " :rules="rules.phone"></v-text-field>
            </v-col>
        </v-row>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-text-field label="Correo electrónico " v-model="values.email " :rules="rules.email"></v-text-field>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'proveedor-form',
    props: ['rules', 'values'],
    data(){
        return {
            
        }
    },
}
</script>

<style>

</style>