<template>
    <div>
        <v-app-bar app color="background" elevation="0"  class="app-header " >
            <v-app-bar-nav-icon class="mx-0 d-lg-none" @click.stop="$store.state.menu = !$store.state.menu"></v-app-bar-nav-icon>
            <div class="d-flex flex-column align-left">
                <!-- <v-btn v-if="$store.state.menu" dark icon text @click="$store.state.minimenu = !$store.state.minimenu">
                    <v-icon>mdi-menu</v-icon>
                </v-btn> --> 
                
                <transition name="fade" mode="out-in">
                <h4 class="page-title">{{ $route.meta.title }}</h4>
            
                </transition>
                
                <span class="caption d-none d-md-flex">{{hours}}:{{minutes}}:{{seconds}} | {{getDate}}</span>
            </div>

            <v-spacer></v-spacer>
          

            <v-menu offset-y>
                
                <template v-slot:activator="{ on }">
                    <v-btn  v-on="on" color="white" elevation="0" class="user-button">
                        <v-avatar color="primary" rounded size="30">
                            <span class="white--text">{{user[0]}}</span>
                        </v-avatar>
                        <span class="mx-2 d-none d-sm-flex">{{user}}</span>
                        
                        <!-- <v-icon>mdi-chevron-down</v-icon> -->
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item v-for="(item, index) in user_menu" :key="index">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="logout" link>
                        <v-list-item-title>Cerrar sesión</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
            <v-menu offset-y>
            
            <template v-slot:activator="{ on }" >
                <v-badge
                :value="hover && (notificationsCount>0)"
                color="primary"
                :content="(notificationsCount>9?'9+':notificationsCount)"
                left
                transition="slide-x-transition"
                offset-y="20"
                >
                    <v-hover v-model="hover">
                        <v-btn @click="getHumanTime()" max-width="35" width="35" min-width="35" v-on="on" color="white" elevation="0" class="user-button-business mr-2 ml-1 px-0 ">
                                
                                    <!-- <span class="mx-0 d-md-flex d-none">span title</span> -->
                                
                                    <v-badge
                                    
                                        :color="(notificationsCount>0) ? 'red' : 'transparent'"
                                        dot
                                        overlap
                                        offset-x="10"
                                        offset-y="10"
                                    >
                    
                                        <v-icon class="mx-0 px-0 active-notification">mdi-bell-outline</v-icon>
                                    </v-badge>
                                </v-btn>
                    </v-hover>
                </v-badge>
                
            </template>
            <v-list class="notifications-list" dense >
                <v-list-item v-if="notifications.length == 0">
                    <span class="notifications-empty">No hay notificaciones</span>
                </v-list-item>
                <v-list-item  :class="item.seen? 'notifications-seen  ' : 'notifications-new'" two-line v-for="(item, index) in notifications" :key="index" @click="goTo(item)">

                    <v-list-item-content>
                        <v-list-item-title class="black--text">{{ item.title }}</v-list-item-title>
                        <v-list-item-subtitle class="notifications-body-text">{{item.body}}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-list-item-action-text ><span class="notifications-body-text">{{item.time}}</span></v-list-item-action-text>
                        <v-icon
                      
                            color="transparent"
                        >
                        mdi-star-outline
                        </v-icon>
                    </v-list-item-action>
                </v-list-item>
                
                
            </v-list>
        </v-menu>
            <div class="line-app-header">
                        
                <template>
                    <v-progress-linear indeterminate :hidden="!this.$store.state.loading"></v-progress-linear>
                </template>
            </div>
        
        
        </v-app-bar>
        <snackbar :props="snackbarProps" @click="(v) => {runAction(v)}" ></snackbar>
    </div>
</template>

<script>
import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');

import NotificationManager from "../helpers/NotificationManager";
import Utils from "../helpers/Utils";
    export default {
        name: 'Navbar',
        data(){
            return {
                snackbarProps: {
                    visible: false,
                    bottom: false,
                    color: 'blue',
                    left: false,
                    multiline: true,
                    right: true,
                    timeout: 3000,
                    top: true,
                    vertical: false,
                    text: '',
                    sub: '',
                    hideClose:true,
                    data:{},
                },
                hover: false,
                hours: 0,
                minutes: 0,
                seconds: 0,
                months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],

                dialog: false,
                items: [],
                user_menu: [],
                notifications: (this.$store.state.userData.notifications.map(itm => {return {...itm, time: Utils.timeSince(itm.created_at)}}))??[],
                // [
                //     // {
                //     //     title: "1 ksjdhfkjsdhfkjsdhfksjd",
                //     //     body: "body",
                //     //     seen: 0,
                //     //     model: "Requisition",
                //     //     model_id: "REQ3652",
                //     //     action: {action: "requisitions.edit", id:"REQ3652"},
                //     // }, 
                //     // {
                //     //     title: "1 ksjdhfkjsdhfkjsdhfksjd",
                //     //     body: "body",
                //     //     seen: 1,
                //     //     model: "Requisition",
                //     //     model_id: "REQ3652",
                //     //     action: {action: "requisitions.edit", id:"REQ3652"},
                //     // }, 
                //     // {
                //     //     title: "1 ksjdhfkjsdhfkjsdhfksjd",
                //     //     body: "body",
                //     //     seen: 0,
                //     //     model: "Requisition",
                //     //     model_id: "REQ3652",
                //     //     action: [],
                //     // }, 
                    
                // ],
                menu: [
                    {
                        title: 'Feature',
                        link: '/'
                    },
                ]
            }
        },
        computed: {
            getDate: function(){
                const actualDate = new Date();
                return `${actualDate.getDate()} ${this.months[actualDate.getMonth()]} ${actualDate.getFullYear()}`;
            },
            user: function() {
                return this.$store.state.loggedUsername
            },
            notificationsCount: function(){
                let notCount =  this.notifications.filter((item) => item.seen == 0).length
                console.log("notifications count run", notCount)
                // if(notCount > 9)
                //     return "9+";
                return notCount;
            }
            
        },
        methods: {
            connectEcho()
            {
                let useSSL = false;
                let transports = ['ws']
                if(process.env.VUE_APP_WEBSOCKET_SSL == "true"){
                    useSSL = true;
                    transports.push("wss");
                }

                window.Echo = new Echo({
                    broadcaster: 'pusher',
                    key: process.env.VUE_APP_PUSHER_APP_KEY,
                    cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
                    encrypted: useSSL,
                    wsHost: process.env.VUE_APP_WEBSOCKET_HOST,
                    wssHost: process.env.VUE_APP_WEBSOCKET_HOST,
                    wsPort: process.env.VUE_APP_WEBSOCKET_PORT,
                    wssPort: process.env.VUE_APP_WEBSOCKET_PORT,
                    forceTLS: useSSL,
                    disableStats: true,
                    enabledTransports: transports,
                    authEndpoint: process.env.VUE_APP_WEBSOCKET_AUTH_HOST,
                    auth: {
                        headers: {
                            'Authorization': `${localStorage.getItem("access_token_eme")}`,
                            Accept: "application/json",
                        },
                    },
                });

                window.Echo.private(`user_notification_${this.$store.state.userData.id}`)
                .error((error) => {
                    console.error(error);
                })
                .listen('UserAppNotificationEvent', (e) => {
                    e.data['time'] = Utils.timeSince(e.data.created_at);
                    console.log(e)
                    this.notifications = [e.data].concat(this.notifications);
                    this.snackbarProps.visible = true;
                    
                    this.snackbarProps.text = e.data.title
                    this.snackbarProps.sub = e.data.body
                    this.snackbarProps.data = e.data;
                    // this.$store.state.globalSnackbarProps.color = "success";
                });

                console.log("echo run", window.Echo)
            },
            goTo(item)
            {
                item.seen = 1;
                
                NotificationManager.setLastNotification(item.id)
                console.log(this.$router.history.current.name)
                
                switch (item.action.action) {
                    case "requisitions.edit":
                            if(this.$gates.hasAllPermissions("requisitions.review")){
                                this.$router.push({ name: 'RequisicionesRevisar', params: { id: item.action.id }}).catch(()=>{});
                                
                            }else{
                                this.$router.push({ name: 'RequisicionesEdicion', params: { id: item.action.id }}).catch(()=>{});
                            }
                        break;

                    case "requisitions.rejected":
                    case "requisitions.approved":
                            this.$router.push({ name: 'RequisicionesEdicion', params: { id: item.action.id }}).catch(()=>{});
                        break;
                
                    default:
                        break;
                }
                
                
            },
            runAction()
            {
                this.goTo(this.snackbarProps.data);
                
            },
            getTime: function(){
                setInterval(() => {
                    const date = new Date()
                    this.hours = date.getHours()
                    this.minutes = this.checkSingleDigit(date.getMinutes())
                    this.seconds = this.checkSingleDigit(date.getSeconds())
                }, 1000)
            },
            checkSingleDigit (digit) {
                return ('0' + digit).slice(-2)
            },
            logout(){
                this.$http.post(this.$store.state.apiRoute+'/logout', [])
				.then(() => {
                    navigator.serviceWorker.getRegistrations().then(function(registrations) {
                        for(let registration of registrations) {
                            registration.unregister();
                        } 
                    });
                    
                    localStorage.removeItem('access_token_eme');
                    NotificationManager.removeLastNotification();
                    this.$store.state.layout = 'login'
                    this.$store.state.userData = null;
                    // next('login')
                    this.$router.push("login");
                    location.reload();

                })
                .catch(function (error) {
                    error
                })
            },

            getHumanTime()
            {
                
                for (let i = 0; i < this.notifications.length; i++) {
                    const element = this.notifications[i];
                    console.log(element);
                    element.time = Utils.timeSince(element.created_at);
                }
            }
        },
        mounted(){
            this.getTime();
            this.connectEcho();
        }
    }
</script>
<style lang="scss">
    .app-header{
        margin: 7px;
        box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75) !important;
        -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75) !important;
        -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75) !important;
        @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
            margin: 0px;
        }
    }

    .app-toolbar{
        padding: 0 !important;
    }
   .parent-class >>> .v-toolbar__content {
      padding: 0px !important;
    }

    .user-button{
        text-transform: unset !important;

        &-business{
            @media (max-width: #{map-get($grid-breakpoints, 'md')}) {
                //width: 100px;
            }
        }
    }

    .page-title{
        @media (max-width: #{map-get($grid-breakpoints, 'sm')}) {
           font-size: 0.75rem;
        }
    }

    .line-app-header{
        width: calc(100% - 32px);
        height: 1.5px;
        background: $lineColor-1;
        position: absolute;
        bottom: 0px;
        left: 16px;
        z-index: -10;
    }

    .v-toolbar__content{
        margin: 0 7px !important;
    }


    .notifications {
    
    
        &-list{
            max-height: 400px;
            overflow-y: scroll;
            width: min-content;
        }
        &-seen{
            // background-color: red !important;
        }
        &-new {
            background-color: $lineColor-1;
        }

        &-body-text{
            font-size: .65rem !important;
        }

        &-empty{
            min-width: 200px;
            font-size: .8rem !important;
        }
    }
</style>