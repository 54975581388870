<template>
    <div>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0" cols="12" md="6">
                <v-autocomplete :items="items.clients" label="Cliente *" :value="values.client_id " :rules="rules.client_id" :loading="loadingClients" append-icon="mdi-chevron-down" disabled></v-autocomplete>
            </v-col>
            <v-col class="py-0">
                <v-autocomplete :items="items.buildings" label="Obra *" :value="values.building_id " :rules="rules.building_id" :loading="loadingBuildings" append-icon="mdi-chevron-down" disabled></v-autocomplete>
            </v-col>
        </v-row>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0" cols="12" md="6">
                <v-text-field label="Descuento (%)" v-model="values.discount" :rules="rules.discount"></v-text-field>
            </v-col>
            <v-col class="py-0">
                <datepicker-dialog :picker="dateProps" :dateVal="values.date" @setDate="v => values.date = v" :rules="rules.date"></datepicker-dialog>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="text-uppercase font-weight-bold primary--text text-h5">
                    Productos
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card flat>
                    <v-card-text class="px-0">
                        <v-data-table :headers="tableProps.headers" :items="values.products" disable-sort disable-pagination> <!-- :loading="table_loading" -->
                            <template v-slot:item.quantity="props">
                                <v-edit-dialog :return-value.sync="props.item.quantity" @save="changeSubtotal(props.item.big_product_id)"> 
                                    <!--@cancel="cancelEdition" @open="open" @close="close" -->
                                    <div class="primary--text" style="font-weight: 700;">{{ props.item.quantity }}</div>
                                    <template v-slot:input>
                                        <v-text-field v-model="props.item.quantity" label="Cantidad"></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </template>

                            <template v-slot:item.amount="props">
                                <v-edit-dialog :return-value.sync="props.item.amount" @save="changeSubtotal(props.item.big_product_id)"> 
                                    <!--@cancel="cancelEdition" @open="open" @close="close" -->
                                    <div class="primary--text" style="font-weight: 700;">{{ props.item.amount }}</div>
                                    <template v-slot:input>
                                        <v-text-field v-model="props.item.amount" label="Costo"></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </template>

                            <template v-slot:item.actions="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn x-small class="mx-0" icon @click="removeProduct(item.big_product_id)">
                                            <v-icon color="secondary" v-on="on">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Borrar</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    props: ['rules', 'values'],
    data(){
        return {
            product_id: '',
            loadingClients: false,
            loadingBuildings: false,
            loadingProducts: false,
            products: [],
            // variantRules: {
            //     price: [
            //         v => !!v || 'Precio es requerido'
            //     ],
            // },
            items: {
                clients: [],
                buildings: [],
                products: []
            },
            dateProps: {
                visible: false,
                date: '',
                width: '300px',
                label: 'Fecha',
                icon: 'mdi-calendar',
                btn: {
                    cancelColor: '',
                    okColor: 'primary'
                }
            },
            tableProps: {
                headers: [
                    {
                        text: 'Producto',
                        align: 'left',
                        value: 'product',
                        width: '40%',
                        class: 'primary--text uppercase--text'
                    },
                    {
                        text: 'Cantidad',
                        align: 'center',
                        value: 'quantity',
                        width: '10%',
                        class: 'primary--text uppercase--text'
                    },
                    {
                        text: 'Costo',
                        align: 'center',
                        value: 'amount',
                        width: '10%',
                        class: 'primary--text uppercase--text'
                    },
                    {
                        text: 'Subtotal',
                        align: 'center',
                        value: 'subtotal',
                        width: '10%',
                        class: 'primary--text uppercase--text'
                    },
                    {
                        text: 'Acciones',
                        align: 'right',
                        value: 'actions',
                        width: '10%',
                        class: 'primary--text uppercase--text'
                    },
                ],
                // items: []
            }
        }
    },
    mounted(){
        this.index()
    },
    methods: {
        index(){
            this.loadingClients = true
            this.$http.get(this.$store.state.apiRoute+'/getClients')
            .then((response) => { this.items.clients = response.data })
            .catch(() => { })
            .finally(() => { this.loadingClients = false })

            this.loadingBuildings = true
            this.$http.get(this.$store.state.apiRoute+'/getBuildings')
            .then((response) => { this.items.buildings = response.data })
            .catch(() => { })
            .finally(() => { this.loadingBuildings = false })
            
            this.loadingProducts = true
            this.$http.get(this.$store.state.apiRoute+'/getBigProducts')
            .then((response) => { this.items.products = response.data })
            .catch(() => { })
            .finally(() => { this.loadingProducts = false })
        },
        getProductName(id){
            var product = this.items.products.find(x => x.value === id)
            return product.text.split('|').join(' | ')
        },
        addProducts(){
            if(this.product_id != ''){
                var validation = this.values.products.findIndex(x => x.big_product_id === this.product_id)
                if(validation < 0){
                    var product = this.items.products.find(x => x.value === this.product_id)
                    var product_t = {
                        big_product_id: this.product_id,
                        product: this.getProductName(this.product_id),
                        quantity: 1,
                        amount: product.price,
                        subtotal: product.price,
                        actions: ''
                    }
                    this.values.products.push(product_t)
                }
            }
        },
        changeSubtotal(id){
            let found = this.values.products.findIndex(element => element.big_product_id == id)            
            this.values.products[found].subtotal = parseFloat(this.values.products[found].amount) * parseFloat(this.values.products[found].quantity)
        },
        removeProduct(id){
            let found = this.values.products.findIndex(element => element.big_product_id == id)
            this.values.products.splice(found, 1)
        },
        save(){
            this.$emit('save')
        }
    }
}
</script>

<style>

</style>