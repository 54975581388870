<template>
    <div>
        <v-card flat>
            <v-card-text class="px-2" v-for="(section, i) in detailsItems" :key="i">
                <v-row justify="center" v-if="section.title != null">
                    <v-col>
                        <h2 class="primary--text">{{ section.title }}</h2>
                    </v-col>
                </v-row>
                <v-row justify="center" no-gutters>
                    <v-col>
                        <div v-for="(item, j) in section.items" :key="j">
                            <v-row justify="center" align="center" no-gutters class="my-2">
                                <v-col v-if="item.concepto !== null"><span class="font-weight-bold primary--text">{{ item.concepto }}</span></v-col>
                                <v-col :class="{'text-right': item.concepto !== null}">
                                    <v-skeleton-loader v-if="item.valor === null" ref="skeleton" type="text" class="mb-0 mx-auto"></v-skeleton-loader>
                                    <!-- <span v-else-if="item.valor === ''"> - </span> -->
                                    <span v-else>{{ item.valor }}</span>
                                </v-col>
                            </v-row>
                            <v-divider></v-divider>
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
export default {
    name: 'details-card',
    props: ['detailsItems'],    
    data: () => ({

    }),
}
</script>

<style>
.v-skeleton-loader__text{
    margin-bottom: 0px;
}
</style> 