<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center">
                    <v-col class="text-center">
                        <div class="text-uppercase font-weight-bold primary--text text-h5">
                            Detalles de la obra
                        </div>
                    </v-col>
                </v-row>
                <v-card-text>
                    <v-row justify="center" no-gutters>
                        <v-col cols="12" md="6" lg="4">
                            <details-card :detailsItems="detailsItems"></details-card>        
                        </v-col>
                    </v-row>
                </v-card-text>
            </div>
            
            <v-row justify="center" align="end">
                <v-col cols="12" md="6" lg="4" class="text-right">
                    <v-btn class="full-width-btn text-base-color" color="secondary" tile :to="{ name: 'Obras' }">Regresar</v-btn>
                </v-col>
            </v-row>
        </template>
    </contentCard>
</template>

<script>
export default {
    data(){
        return {
            loading: false,
            id: this.$route.params.id,
            detailsItems: [
                {
                    title: null, 
                    items: [
                        { concepto: 'Nombre', index: 'name', valor: null }
                    ],
                }
            ]
        }
    },
    methods: {
        index(){
            this.loading = true;
            this.$http.get(this.$store.state.apiRoute+'/buildings/'+this.id+'/edit')
            .then((response) => {
                this.detailsItems.forEach((x) => {
                    x.items.forEach((y) => {
                        y.valor = response.data[y.index]
                    })
                })
            })
            .catch(error => {
                error
            })
            .finally(() => {
                this.loading = false
            })
        }
    },
    mounted(){
        this.index()
    }
}
</script>

<style>
</style>