<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center">
                    <v-col class="text-center">
                        <div class="text-uppercase font-weight-bold primary--text text-h5">
                            Editar productos
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="5">
                        <v-form ref="form" @submit.prevent="save">
                            <producto-form :values="form" :rules="rules" @save="save"></producto-form>
                        </v-form>
                    </v-col>
                </v-row>

            </div>
            <v-row justify="center" align="end">
				<v-col cols="12" md="5" class="text-right">
					<v-btn class="full-width-btn" tile text color="primary" :to="{ name: 'Productos' }">Cancelar</v-btn>
					<v-btn tile color="secondary" class="ml-md-2 ml-0 mt-md-0 mt-3 full-width-btn text-base-color" @click="save">Guardar</v-btn>
				</v-col>
			</v-row>
            <snackbar :props="snackbarProps"></snackbar>
        </template>
    </contentCard>
</template>

<script>
import ProductoForm from "./Form.vue";
export default {
    components: {
        'producto-form': ProductoForm,
    },
	data(){
		return {
            id: this.$route.params.id,
            loading: false,
            response: {
                "data": {
                    "product": {
                        "name": "Coquita",
                        "description": "Una coquita bien rica y más fría",
                        "brand": "Refresco",
                        "unit_id": "mili-litros"
                    },
                    "suppliers": [
                        {
                            "name": "Aceros Del Bajío",
                            "slug": "aceros-del-bajio",
                        },
                        {
                            "name": "Proveedor Dos",
                            "slug": "proveedor-dos",
                        }
                    ],
                    "variants": [
                        {
                            "id": "1",
                            "size": "chica",
                            "color": "negro",
                            "price": "9.5"
                        },
                        {
                            "id": "2",
                            "size": "mediana",
                            "color": "azul",
                            "price": "13"
                        },
                    ],
                }
            },
            form: {
                name: '',
                type: '',
                description: '',
                brand: '',
                unit_id: '',
                suppliers: [],
                variants: [],
            },
            rules: {
               name: [
                    v => !!v || 'Nombre es requerido'
                ],
                type: [
                    v => v.length > 0 || 'Tipo es requerido'
                ],
                description: [
                    v => !!v || 'Descripción es requerido'
                ],
                brand: [
                    v => !!v || 'Marca es requerido'
                ],
                unit_id: [
                    v => !!v || 'Unidad es requerido'
                ],
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        this.index()
    },
	methods: {
		validate () {
			return this.$refs.form.validate()
        },
        index(){
            this.loading = true
            this.$http.get(this.$store.state.apiRoute+'/bigProducts/'+this.id+'/edit')
            .then((response) => {
                this.form = {
                    // // name: response.data.name,
                    name: response.data.name,
                    description: response.data.description,
                    price: response.data.price,
                }
            })
            .catch(error => {
                error
            })
            .finally(() => {
                this.loading = false
            })
        },
        save(){
            this.$store.state.overlay = true

            if(this.validate()){
                this.$http.put(this.$store.state.apiRoute+'/bigProducts/'+this.id+'/update', this.form)
                .then((response) => {
                    response.data

                    this.$store.state.globalSnackbarProps.visible = true
                    this.$store.state.globalSnackbarProps.text = 'Se ha actualizado correctamente la materia prima <b>'+this.form.name+'</b>'
                    this.$router.push({ name: 'Productos' })
                })
                .catch((error) => {
                    var errors = []
                    switch(error.response.status){
                        case 422: 
                            Object.keys(error.response.data.errors).forEach((x) => {
                                error.response.data.errors[x].forEach((y) => {
                                    errors.push(y)
                                })
                            })
                        break;
                        case 500: 
                            errors.push('Error en el servidor. Intente de nuevo.')
                        break;
                        case 404: 
                            errors.push('No encontrado.')
                        break;
                    }

                    this.snackbarProps = {
                        visible: true,
                        color: 'error',
                        timeout: 10000,
                        text: errors.join('.<br>'),
                    }
                })
                .finally(() => {
                    this.$store.state.overlay = false
                })
            }else{
                this.$refs.ProductoForm.moveStep(1)
                this.snackbarProps = {
                    visible: true,
                    color: 'error',
                    timeout: 2000,
                    text: 'Formulario incompleto',
                }

                this.$store.state.overlay = false
            }
        }
    },
}
</script>

<style>

</style>