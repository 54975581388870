import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    layout: 'loading',
    // apiRoute: process.env.NODE_ENV === 'production' ? 'https://api.grupomerca.com.mx/api' : 'https://sandbox.grupomerca.com.mx/api',
    apiRoute: process.env.VUE_APP_BACKEND_URL,
    // s3Route: 'https://sistemaeme.s3.us-east-2.amazonaws.com',
    s3Route: process.env.VUE_APP_S3_URL,
    flujoRoute: process.env.VUE_APP_FLUJO_URL,
    minimenu: false,
    authToken: '',
    menu: true,
    loggedIn: false,
    loggedUsername: '',
    loggedUserRole: '',
    userData: null,
    notificationSnackbarProps: {
        visible: false,
        bottom: false,
        color: 'info',
        left: false,
        multiline: true,
        right: true,
        timeout: 4000,
        top: true,
        vertical: false,
        text: '',
        sub: '',
    },
    globalSnackbarProps: {
      visible: false,
      bottom: false,
      color: 'success',
      left: false,
      multiline: false,
      right: true,
      timeout: 4000,
      top: true,
      vertical: false,
      text: ''
    },
    overlay: false,
    countryApiRoute: process.env.VUE_APP_LOCATIONS_API_URL,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
