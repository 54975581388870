const permissions = {
    root: false,
    
    roles: {
    },
    users: {
        
    },
    bigProducts: {
        
    },
    banks: {},
    purchaseOrders: {
       
    },
    requisitions: {
       
    },
    saleOrders: {
       
    },
    quotes: {
       
    },
    sellers: {
      
    },
    clients: {
       
    },
    products: {
      
    },
    suppliers: {
      
    },
    buildings: {
       
    },
    catalog_bank: {
      
    },
    catalog_unit: {
        
    },
    concepts:{},

};

export default permissions;
