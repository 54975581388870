export default class Utils{

    static timeSince(ts){
        let now = new Date();
        ts = new Date(ts*1000);
        var delta = now.getTime() - ts.getTime();
    
        delta = delta/1000; //us to s
    
        var ps, pm, ph, pd, min, hou, sec, days;
        
        if(delta<=59){
            ps = (delta>1) ? "": "";
            return Math.round(delta)+" seg"+ps
        }
    
        if(delta>=60 && delta<=3599){
            min = Math.floor(delta/60);
            sec = delta-(min*60);
            pm = (min>1) ? "": "";
            ps = (sec>1) ? "": "";
            return min+" min"+pm+" ";
        }
    
        if(delta>=3600 && delta<=86399){
            hou = Math.floor(delta/3600);
            min = Math.floor((delta-(hou*3600))/60);
            ph = (hou>1) ? "s": "";
            pm = (min>1) ? "s": "";
            return hou+" hr"+ph+" ";
        } 
    
        if(delta>=86400 && delta <= 2591999){
            days = Math.floor(delta/86400);
            hou =  Math.floor((delta-(days*86400))/60/60);
            pd = (days>1) ? "s": "";
            ph = (hou>1) ? "s": "";
            return days+" día"+pd+" ";
        }

        if(delta>=2592000){
            days = Math.floor(delta/2592000);
            hou =  Math.floor((delta-(days*2592000))/60/60);
            pd = (days>1) ? "es": "";
            ph = (hou>1) ? "es": "";
            return days+" mes"+pd+" ";
        }
    
    }


}