
import VueGatesPlugin from '../plugins/vue-gates';
import store from '../store';

export default function VueGateMiddleware({to,next}) {
    let watcher = setInterval(() => {
        if (store.state.userData != null) {
            if(checkPermission(to)){
                // console.log("fue next", checkPermission(to));
                next();
            }
            else{
                // console.log("fue /", checkPermission(to));
                next("/dashboard");
            }
            clearInterval(watcher);
        }
    }, 100);
   
}







function checkPermission(params) {
    return VueGatesPlugin.checkRoutePermission(params);
        
}