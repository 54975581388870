<template>
    <div>
        <!-- <v-row align-content="center" justify="center">
            <v-col>
                <v-autocomplete label="Empleado *" :items="items.sellers" v-model="values.seller_id" :rules="rules.seller_id" :disabled="edit"></v-autocomplete>
            </v-col>
        </v-row> -->

        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-text-field label="Nombre *" v-model="values.name" :rules="rules.required"></v-text-field>
            </v-col>
        </v-row>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-text-field label="Teléfono *" counter="10" v-model="values.phone " :rules="rules.phone"></v-text-field>
            </v-col>
        </v-row>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-text-field label="Correo electrónico " v-model="values.email " :rules="rules.email"></v-text-field>
            </v-col>
        </v-row>
       
        <v-row align-content="center" justify="center">
            <v-col>
                <v-autocomplete label="rol *" :items="items.roles" v-model="values.role_id" :rules="rules.required"></v-autocomplete>
            </v-col>
        </v-row>
        <!-- <v-row align-content="center" justify="center">
            <v-col>
                <v-text-field label="Correo *" v-model="values.email" disabled></v-text-field>
            </v-col>
        </v-row> -->
        <v-row align-content="center" justify="center" v-if="!edit">
            <v-col>
                <v-text-field label="Contraseña *" v-model="values.password" :rules="rules.password" counter :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'" :type="showPass ? 'text' : 'password'" @click:append="showPass = !showPass"></v-text-field>
            </v-col>
        </v-row>
        <v-row align-content="center" justify="center" v-if="!edit">
            <v-col>
                <v-text-field label="Confirmar contraseña *" v-model="values.password_confirmation" :rules="rules.password_confirmation" counter :append-icon="showPassCon ? 'mdi-eye' : 'mdi-eye-off'" :type="showPassCon ? 'text' : 'password'" @click:append="showPassCon = !showPassCon"></v-text-field>
            </v-col>
        </v-row>
        <v-row align-content="center" justify="center">
            <v-col>
                <v-switch v-model="values.status" inset :label="`Estatus: ${values.status}`" true-value="Activo" false-value="Inactivo"></v-switch>
            </v-col>
        </v-row>
        <v-row align-content="center" justify="center">
            <v-col>
                <v-autocomplete multiple label="Empresas *" :loading="loadingBusiness" :items="items.businesses" v-model="values.business" :rules="rules.business">
                    <template v-slot:selection="data">
                        <v-chip
                            class="mt-1 mb-1"
                            color="primary"
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            close
                            @click="data.select"
                            @click:close="remove(data)"
                        >
                        {{ data.item.text }}
                        </v-chip>
                    </template>


                    <template v-slot:item="data">
                        <template v-if="typeof data.item !== 'object'">
                            <v-list-item-content v-text="data.item"></v-list-item-content>
                        </template>
                        <template v-else>
                        
                        <v-list-item-content>
                            <v-list-item-title v-html="data.item.text"></v-list-item-title>
                        </v-list-item-content>
                        </template>
                    </template>
                </v-autocomplete>
            </v-col>
        </v-row>
    </div>
</template>
<script>
export default {
    props: ['values', 'edit'],
    computed: {
        // seller_id(){
        //     return this.values.seller_id
        // }
    },
    data(){
        return{
            showPass: false,
            showPassCon: false,
            loadingBusiness:false,
            items: {
                // sellers: [],
                roles: [],
                businesses:[],
            },
            rules: {
                required: [
					v => !!v || 'El campo es requerido'
				],
				seller_id: [
					v => !!v || 'Nombre es requerido'
				],
				password: [
					v => !!v || 'Contraseña es requerido'
				],
				password_confirmation: [
					v => !!v || 'Confirmación de contraseña es requerido',
					v => v == this.values.password || 'Las contraseñas no coinciden'
				],
                phone: [
                    v => !!v || 'Teléfono es requerido',
                    v => /^[0-9]*$/.test(v) || 'Teléfono sólo debe contener números',
                    v => v.length === 10 || 'Teléfono debe contener 10 digitos'
                ],
                email: [
                    v => !!v || 'El correo es requerido',
                    v => (v.length == 0 || /.+@.+\..+/.test(v)) || 'Correo debe ser con formato válido',
                ],
                business: [
                    v => (v.length > 0 ) || 'Al menos una empresa',
                ],
			},
        }
    },
    mounted(){
        // this.getEmployees();
        this.getRoles();
        this.getBusiness();
    },
    methods: {
        remove (item) {
            this.values.business.splice(item.index, 1)
            
        },
        // getEmployees(){
        //     this.$http.get(this.$store.state.apiRoute+'/getSellers')
        //     .then((response) => {
        //         this.items.sellers = response.data
        //     })
        // },
        getRoles(){
            this.$http.get(this.$store.state.apiRoute+'/getRoles')
            .then((response) => {
                this.items.roles = response.data;
            })
        },

        getBusiness()
        {
            this.loadingBusiness = true;
            this.$http.get(this.$store.state.flujoRoute+'/external/business')
            .then((response) => {
                this.items.businesses = response.data;
            })
            .finally(() => {this.loadingBusiness = false;});
        }
    },
    watch: {
        // seller_id(newVal){
        //     this.$http.get(this.$store.state.apiRoute+'/getEmail/'+newVal)
        //     .then((response) => {
        //         this.values.email = response.data.email
        //     })
        // }
    }
}
</script>