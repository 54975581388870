<template>
    <div>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-autocomplete :items="items.categories" label="Rubro" v-model="values.category" :rules="rules.required" append-icon="mdi-chevron-down" :disabled="edition"></v-autocomplete>
            </v-col>
        </v-row>

        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-text-field label="Descripción *" v-model="values.description" :rules="rules.required"></v-text-field>
            </v-col>
        </v-row>

        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-text-field label="Año *" v-model="values.year" :rules="rules.year"></v-text-field>
            </v-col>
        </v-row>

        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-autocomplete :items="items.units" label="Unidad *" v-model="values.catalog_id " :rules="rules.required" :loading="loadingUnits"></v-autocomplete>
            </v-col>
        </v-row>
        
    </div>
</template>

<script>
export default {
    name: 'requisition-form',
    props: [ 'values', 'edition'],
    computed: {
       
    },
    data(){
        return {
            product_id: '',
            loadingUnits: false,
            products: [],
            items: {
                categories: [ //Service,Active,Maintenance,Consumable
                    { text: 'Servicios', value: 'Service' },
                    { text: 'Activos', value: 'Active' },
                    { text: 'Mantenimientos', value: 'Maintenance' },
                    { text: 'Consumibles', value: 'Consumable' },
                ],
                units: []
            },
           
            rules: {
                required: [
                    v => !!v || 'El campo es requerido'
                ],
                year: [
                   v => {
                        if(v === null || v.toString().trim() === '')
                            return "El campo es requerido";
                        if(v!= null && !v.toString().match("^[0-9]{1,4}?$"))
                            return 'Solo números válidos';
                        
                        return true;
                    }

                ],
               
                
            },
           
        }
    },
    mounted(){
        this.getUnits();

    },
    methods: {
        getUnits()
        {
            this.loadingUnits = true
            this.$http.get(this.$store.state.apiRoute+'/catalogs/getUnits')
            .then((response) => { this.items.units = response.data })
            .catch(() => { })
            .finally(() => { this.loadingUnits = false })
        },

       
    },
    watch: {
        
    }
}
</script>

<style>

</style>