<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center">
                    <v-col class="text-center">
                        <div class="text-uppercase font-weight-bold primary--text text-h5">
                            Registrar nuevo banco
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="4">
                        <v-form ref="form" @submit.prevent="save">
                            <v-row no-gutters>
                                <v-col>
                                    <v-text-field v-model="form.name" label="Nombre *" :rules="rules.name"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-col>
                </v-row>
            </div>
			
			<v-row justify="center" align="end">
				<v-col cols="12" md="4" class="text-right">
					<v-btn class="full-width-btn" tile text color="primary" :to="{name: 'CatalogosBancos'}">Cancelar</v-btn>
					<v-btn tile color="secondary" class="ml-md-2 ml-0 mt-3 mt-md-0 full-width-btn text-base-color" @click="save">Guardar</v-btn>
				</v-col>
			</v-row>
        <snackbar :props="snackbarProps"></snackbar>
        </template>
    </contentCard>
</template>

<script>
export default {
	data(){
		return {
            loading:false,
			form: {
				name: '',
				type: 'Bank'
			},
			rules: {
				name: [
					v => !!v || 'Nombre es requerido'
				]
			},
			snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
			},
		}
	},
	methods: {
		validate () {
			return this.$refs.form.validate()
		},
		save(){
			if(this.validate()){
				this.$store.state.overlay = true
				this.$http.post(this.$store.state.apiRoute+'/catalogs/bank/store', this.form)
				.then(() => {
					this.$store.state.globalSnackbarProps.visible = true
					this.$store.state.globalSnackbarProps.text = 'Se ha registrado correctamente el banco en el catálogo'
					this.$router.push({ name: 'CatalogosBancos' })
				}).catch((error) => {
					var errors = []
					switch(error.response.status){
						case 422: 
							Object.keys(error.response.data.errors).forEach((x) => {
								error.response.data.errors[x].forEach((y) => {
									errors.push(y)
								})
							})
						break;
						case 500: 
							errors.push('Error en el servidor. Intente de nuevo.')
						break;
						case 404: 
							errors.push('404 Not found.')
						break;
                        case 403: 
                            errors.push('Acceso denegado.')
						break;
					}

					this.snackbarProps = {
						visible: true,
						color: 'error',
						timeout: 10000,
						text: errors.join('.<br>'),
					}
				})
				.finally(() => {
					this.$store.state.overlay = false
				})
			}else{
				this.snackbarProps = {
					visible: true,
					color: 'error',
					timeout: 2000,
					text: 'Formulario incompleto',
				}

				this.$store.state.overlay = false
			}
		}
	}
}
</script>

<style>

</style>