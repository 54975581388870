<template>
	<div>
		<v-card flat color="transparent">
			<v-card-text>
				<v-row align="center">
					<v-col cols="12" md="4">
						<v-text-field solo flat outlined dense v-model="tableProps.search" label="Buscar..." clearable append-icon="mdi-magnify" hide-details></v-text-field>
					</v-col>
					<v-spacer></v-spacer>
					<v-col class='flexbox-right' cols="12" md="2" v-permission="'concepts.create'">
						<v-btn class='btn-yellow full-width-btn' elevation="0" :to="{ name: 'ConceptosRegistro' }" tile>
                            Nuevo concepto
                            <v-icon
                                class="ml-2"
                                color=""
                            >
                                mdi-plus
                            </v-icon>
                        </v-btn>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<datatable class="text-uppercase" :props="tableProps" @method_handler="method_handler"></datatable>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>

        <modal :props="modalProps" @modalResponse="method_handler"></modal>

	</div>
</template>

<script>
export default {
	
	data(){
		return {
            actions: [
               
                {
                    name: 'Editar',
                    icon: {
                    color: 'secondary',
                        icon: 'mdi-pencil'
                    },
                    disabled: false,
                    type: 'redirect',   //  method, external, redirect
                    action: '',
                    permissions: 'concepts.update'
                },
                {
                    name: 'Borrar',
                    icon: {
                        color: 'secondary',
                        icon: 'mdi-delete'
                    },
                    disabled: false,
                    type: 'method',   //  method, external, redirect
                    action: 'removeRecord',
                    permissions: 'concepts.delete'
                },
                
			],
			tableProps: {
				headers: [
					{
						text: 'Codificado',
						align: 'left',
						value: 'serial',
						class: 'primary--text text-uppercase'
					},
					{
						text: 'Rubro',
						align: 'left',
						value: 'category',
						class: 'primary--text text-uppercase'
					},
					{
						text: 'Descripción',
						align: 'left',
						value: 'description',
						class: 'primary--text text-uppercase'
					},
					{
						text: 'Unidad',
						align: 'left',
						value: 'catalog.name',
						class: 'primary--text text-uppercase'
					},
					{
						text: 'Año',
						align: 'left',
						value: 'year',
						class: 'primary--text text-uppercase'
					},
					
					{
						text: 'Acciones',
						align: 'right',
						sortable: false,
						value: 'actions',
						class: 'primary--text text-uppercase'
					}
				],
				items: [],
				search: ''
			},
			modalProps: {
				visible: false,
				width: '500',
				title: 'Hola',
				text: 'Adiós',
				buttons: [
					{
						text: 'Cancelar',
						color: 'error',
						textColor: 'white',
						btnTypeText: true,
						method: 'cancel',
						parameters: []
					},
					{
						text: 'Aceptar',
						color: 'secondary',
						textColor: 'primary--text mx-1',
						btnTypeText: false,
						method: 'confirm',
						parameters: []
					}
				]
			},
			numberFormat: new Intl.NumberFormat('en-us', {minimumFractionDigits: 2})
		}
	},
	mounted(){
		this.index()
	},
	methods: {
		method_handler(object){
			this[object.method_name](object.parameters)
		},
		index() {
			this.$store.state.overlay = true
			
			this.$http.get(this.$store.state.apiRoute+'/concepts')
			.then((response) => {
				this.tableProps.items = response.data.map((x) => {
					x['actions'] = JSON.parse(JSON.stringify(this.actions))

					x.actions[0].action = { name: 'ConceptosEdicion', params: { id: x.serial } }

                    x.category = this.setCategory(x.category);
					x['parameters'] = { id: x.serial }

					return x
				})
			})
			.catch(error => {
				error
			})
			.finally(() => {
				this.$store.state.overlay = false
			})
		},
		removeRecord(parameters){
			this.modalProps = {
				visible: true,
				width: '500',
				title: 'Eliminar concepto',
				text: '¿Desea continuar?',
				buttons: [
					{
						text: 'Cancelar',
						color: 'primary',
						textColor: 'white',
						btnTypeText: true,
						method: 'cancel',
						parameters: {}
					},
					{
						text: 'Aceptar',
						color: 'secondary',
						textColor: 'text-base-color mx-1',
						btnTypeText: false,
						method: 'confirm',
						parameters: {
							id: parameters.id
						}
					}
				]
			}
			this.modalProps.visible = true
		},
		confirm(parameters){
			this.$http.delete(this.$store.state.apiRoute+'/concepts/'+parameters.id+'/delete')
			.then(() => {
				this.snackbar = {
					visible: true,
					color: 'green',
					text: 'Se eliminó correctamente',
					timeout: 3000
				}
				this.index()
			}).catch(error => {
                console.error(error);
				this.snackbar = {
					visible: true,
					color: 'red',
					text: 'Error al eliminar. Intenta de nuevo',
					timeout: 3000
				}
			})
			.finally(() => {
				this.modalProps.visible = false
			})
		},
		cancel(){
			this.modalProps.visible = false
		},
        setCategory(category){
            //Service,Active,Maintenance,Consumable
            if(category == "Maintenance")
                return "Mantenimientos";
            if(category == "Service")
                return "Servicios";
            if(category == "Active")
                return "Activos";
            if(category == "Consumable")
                return "Consumibles";
            
            return category;
        }
	},
}
</script>

<style>

</style>