<template>
    <div>
        <v-row>
            <v-col class="py-0">
                <v-autocomplete :items="items.suppliers" label="Proveedor *" v-model="values.supplier_id" :rules="rules.supplier_id" :loading="loadingSuppliers" append-icon="mdi-chevron-down"></v-autocomplete>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="py-0">
                <v-autocomplete v-if="!edit" :items="items.showConcepts" label="Mostrar conceptos" v-model="selectConcepts" :rules="[]"  append-icon="mdi-chevron-down"></v-autocomplete>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card outlined>
                    <v-card-text>
                        <v-data-table :headers="tableProps.headers" :items="values.concepts" disable-sort disable-pagination> <!-- :loading="table_loading" -->
                            <template v-slot:item.quantity="props">
                                <v-edit-dialog :return-value.sync="props.item.quantity" @save="changeSubtotal(props.item.concept_id)"> 
                                    <!--@cancel="cancelEdition" @open="open" @close="close" -->
                                    <div class="primary--text" style="font-weight: 700;">{{ props.item.quantity }}</div>
                                    <template v-slot:input>
                                        <v-text-field v-model="props.item.quantity" label="Cantidad"></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </template>

                            <template v-slot:item.amount="props">
                                <v-edit-dialog :return-value.sync="props.item.amount" @save="changeSubtotal(props.item.concept_id)"> 
                                    <!--@cancel="cancelEdition" @open="open" @close="close" -->
                                    <div class="primary--text" style="font-weight: 700;">{{ props.item.amount }}</div>
                                    <template v-slot:input>
                                        <v-text-field v-model="props.item.amount" label="Costo"></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </template>

                            <template v-slot:item.actions="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn x-small class="mx-0" icon @click="removeProduct(item.concept_id)">
                                            <v-icon color="secondary" v-on="on">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Borrar</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'proveedor-form',
    props: ['rules', 'values', 'edition', 'edit'],
    watch: {
        [`selectConcepts`](v){
            this.loadSelectedConcepts(v);
            
        }

       
    },
    data(){
        return {
            conceptsBackup:[],
            product_id: '',
            loadingSellers: false,
            loadingBuildings: false,
            loadingSaleOrders: false,
            loadingSuppliers: false,
            loadingProducts: false,
            selectConcepts: 'approved',
            products: [],
            items: {
                sellers: [],
                buildings: [],
                saleOrders: [],
                suppliers: [],

                showConcepts:[
                    {text: "Mostrar todos", value: "all"},
                    {text: "Mostrar Aprobadas", value: "approved"},
                    {text: "Mostrar Solicitadas", value: "requested"},
                ]
            },
            // dateProps: {
            //     visible: false,
            //     date: '',
            //     width: '300px',
            //     label: 'Fecha',
            //     icon: 'mdi-calendar',
            //     btn: {
            //         cancelColor: '',
            //         okColor: 'primary'
            //     }
            // },
            tableProps: {
                headers: [
                    {
                        text: 'Concepto',
                        align: 'left',
                        value: 'name',
                        width: '40%',
                        class: 'primary--text uppercase--text'
                    },
                    {
                        text: 'Unidad',
                        align: 'center',
                        value: 'unit',
                        width: '5%',
                        class: 'primary--text uppercase--text'
                    },
                    {
                        text: 'Cantidad',
                        align: 'center',
                        value: 'quantity',
                        width: '5%',
                        class: 'primary--text uppercase--text'
                    },
                    
                    {
                        text: 'Costo',
                        align: 'center',
                        value: 'amount',
                        width: '10%',
                        class: 'primary--text uppercase--text'
                    },
                    {
                        text: 'Subtotal',
                        align: 'center',
                        value: 'subtotal',
                        width: '10%',
                        class: 'primary--text uppercase--text'
                    },
                    {
                        text: '',
                        align: 'right',
                        value: 'actions',
                        width: '5%',
                        class: 'primary--text uppercase--text'
                    },
                ],
            }
        }
    },
    mounted(){
        
        this.index();
        if(this.edit)
            this.loadSelectedConcepts("all")
        else
            this.loadSelectedConcepts("approved")
    },
    methods: {
        loadSelectedConcepts(selected)
        {
            if(this.tableProps.headers.length == 7)
                this.tableProps.headers.splice(2, 1);
            if(selected == "all"){
                this.values.concepts = JSON.parse(JSON.stringify(this.conceptsBackup.filter((item) => item.status != "Rechazada")));
                this.tableProps.headers.splice(2, 0, 
                    {
                        text: 'Estatus',
                        align: 'center',
                        value: 'status',
                        width: '5%',
                        class: 'primary--text uppercase--text'
                    },
                );
                // console.log(numbers);
            }
            if(selected == "approved"){
                this.values.concepts = JSON.parse(JSON.stringify(this.conceptsBackup.filter((item) => item.status == "Aprobada")));

            }
            if(selected == "requested"){
                this.values.concepts = JSON.parse(JSON.stringify(this.conceptsBackup.filter((item) => item.status == "Solicitada")));
            }

            console.log(this.values.concepts, this.conceptsBackup);
        },
        index(){
            // console.log("index loaded")
            this.conceptsBackup = JSON.parse(JSON.stringify(this.values.concepts));
            // console.log(this.conceptsBackup)
            //this.loadingSellers = true
            //this.$http.get(this.$store.state.apiRoute+'/getSellers')
            //.then((response) => { this.items.sellers = response.data })
            //.catch((error) => { })
            //.finally(() => { this.loadingSellers = false })
            
            // this.loadingBuildings = true
            // this.$http.get(this.$store.state.apiRoute+'/getBuildings')
            // .then((response) => { this.items.buildings = response.data })
            // .catch(() => { })
            // .finally(() => { this.loadingBuildings = false })
            
            // this.loadingSaleOrders = true
            // this.$http.get(this.$store.state.apiRoute+'/getSaleOrders')
            // .then((response) => { this.items.saleOrders = response.data })
            // .catch(() => { })
            // .finally(() => { this.loadingSaleOrders = false })
            
            this.loadingSuppliers = true
            this.$http.get(this.$store.state.apiRoute+'/getSuppliers')
            .then((response) => { this.items.suppliers = response.data })
            .catch(() => { })
            .finally(() => { this.loadingSuppliers = false })            
            
            // this.loadingProducts = true
            // this.$http.get(this.$store.state.apiRoute+'/getProducts')
            // .then((response) => { this.items.products = response.data })
            // .catch(() => { })
            // .finally(() => { this.loadingProducts = false })
        },
        getProductName(id){
            var product = this.items.products.find(x => x.value === id)
            return product.text.split('|').join(' | ')
        },
        addProducts(){
            if(this.product_id != ''){
                var validation = this.values.concepts.findIndex(x => x.concept_id === this.product_id)
                if(validation < 0){
                    // var name = this.items.products.find(x => x.value === this.product_id)
                    var product_t = {
                        concept_id: this.product_id,
                        product_variant: this.getProductName(this.product_id),
                        quantity: 1,
                        unit: 'Pieza',
                        amount: 0,
                        subtotal: '0',
                        actions: ''
                    }
                    this.values.concepts.push(product_t)
                }
            }
        },
        changeSubtotal(id){
            let found = this.values.concepts.findIndex(element => element.concept_id == id);
            this.values.concepts[found].subtotal = (parseFloat(this.values.concepts[found].amount) * parseFloat(this.values.concepts[found].quantity)).toFixed(2);
            console.log(this.values.concepts[found].subtotal)
        },
        removeProduct(id){
            let found = this.values.concepts.findIndex(element => element.concept_id == id);
            this.values.concepts.splice(found, 1)
        },
        save(){
            this.$emit('save')
        }
    }
}
</script>

<style>

</style>