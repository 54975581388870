<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center">
                    <v-col class="text-center">
                        <div class="text-uppercase font-weight-bold primary--text text-h5">
                            Detalles del proveedor
                        </div>
                    </v-col>
                </v-row>
                <v-card-text>
                    <v-row justify="center" no-gutters>
                        <v-col cols="12" md="6" lg="4">
                            <details-card :detailsItems="detailsItems"></details-card>        
                        </v-col>
                    </v-row>
                </v-card-text>
            </div>
               
            <v-row justify="center" align="end">
                <v-col cols="12" md="6" lg="4" class="text-right ">
                    <v-btn class="full-width-btn text-base-color" color="secondary" tile :to="{ name: 'Proveedores' }">Regresar</v-btn>
                </v-col>
            </v-row>
        </template>
    </contentCard>
</template>

<script>
export default {
    data(){
        return {
            loading:true,
            id: this.$route.params.id,
            detailsItems: [
                {
                    title: null, 
                    items: [
                        { concepto: 'Nombre', index: 'name', valor: null },
                        { concepto: 'R.F.C.', index: 'rfc', valor: null },
                        { concepto: 'Razón Social', index: 'business_name', valor: null },
                        { concepto: 'Estado', index: 'address_code', valor: null },
                        { concepto: 'Municipio', index: 'address_code', valor: null },
                        { concepto: 'Colonia', index: 'neighborhood', valor: null },
                        { concepto: 'Calle', index: 'street', valor: null },
                        { concepto: 'No. Exterior', index: 'ext_number', valor: null },
                        { concepto: 'No. Interior', index: 'int_number', valor: null },
                        { concepto: 'Contacto', index: 'contact', valor: null },
                        { concepto: 'Teléfono', index: 'phone', valor: null },
                        { concepto: 'Email', index: 'email', valor: null },
                        
                    ],
                }
            ]
        }
    },
    mounted(){
        this.index()
    },
    methods: {
        index(){
            this.loading = true;
            this.$http.get(this.$store.state.apiRoute+'/suppliers/'+this.id+'/edit')
            .then((response) => {
                this.detailsItems.forEach((x) => {
                    x.items.forEach((y) => {
                        y.valor = response.data[y.index]
                    })
                })
            })
            .catch(error => {
                error
            })
            .finally(() => {
                this.loading = false;
            })
        }
    }
}
</script>

<style>
</style>