<template>
    <div>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-autocomplete :items="items.clients" label="Cliente *" v-model="values.client_id " :rules="rules.client_id" :loading="loadingClients" append-icon="mdi-chevron-down" :disabled="edition"></v-autocomplete>
            </v-col>
        </v-row>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0" cols="12" md="6">
                <v-text-field v-if="!edition" label="Obra *" v-model="values.building_id " :rules="rules.building_id" :disabled="edition"></v-text-field>
                <v-autocomplete v-else :items="items.buildings" label="Obra *" v-model="values.building_id " :rules="rules.building_id" :loading="loadingBuildings" append-icon="mdi-chevron-down" :disabled="edition"></v-autocomplete>
            </v-col>
            <v-col class="py-0">
                <datepicker-dialog :picker="dateProps" :dateVal="values.date" @setDate="v => values.date = v" :rules="rules.date"></datepicker-dialog>
            </v-col>
        </v-row>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-text-field label="Referencia de obra *" v-model="values.reference" :rules="rules.reference" :disabled="edition"></v-text-field>
            </v-col>
        </v-row>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-textarea label="Condiciones" v-model="values.conditions" :rules="rules.conditions"></v-textarea>
            </v-col>
        </v-row>

        <v-row align="center">
            <v-col>
                <div class="text-uppercase font-weight-bold primary--text text-h5">
                    Productos
                </div>
            </v-col>
            <v-col class="text-right">
                <v-btn class="secondary--text " color="white" small @click="new_product = !new_product" v-text="'Crear nuevo'" :class="{ 'clicked': new_product }"></v-btn>
            </v-col>
        </v-row>
        <v-expand-transition>
            <v-row v-if="new_product">
                <v-col>
                    <v-card outlined>
                        <v-card-title class="pb-0">
                            <h5>Nuevo producto</h5>
                        </v-card-title>
                        <v-card-text>
                            <v-form ref="miniform" @submit.prevent="save">
                                <v-row class="py-0" align="center" justify="center">
                                    <v-col class="py-0" cols="12" md="6">
                                        <v-text-field label="Nombre *" v-model="product_form.name" :rules="rules.name"></v-text-field>
                                    </v-col>
                                    <v-col class="py-0">
                                        <v-text-field label="Precio *" v-model="product_form.price" :rules="rules.price"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn small tile text color="primary" @click="$refs.miniform.reset(); new_product = false">Cancelar</v-btn>
                            <v-btn small tile color="secondary" class="ml-2 " @click="saveProduct">Guardar y agregar</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-expand-transition>
        <v-expand-transition>
            <v-row v-if="!new_product">
                <v-col class="py-0">
                    <v-autocomplete :items="items.products" label="Producto *" v-model="product_id" :loading="loadingProducts" append-icon="mdi-chevron-down"></v-autocomplete>
                </v-col>
            </v-row>
        </v-expand-transition>
        <v-row>
            <v-col class="py-0 text-right">
                <v-btn class="py-0 full-width-btn" color="primary" text tile @click="addProducts">Agregar</v-btn>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card outlined>
                    <v-card-text>
                        <v-data-table :headers="tableProps.headers" :items="values.products" disable-sort disable-pagination> <!-- :loading="table_loading" -->
                            <template v-slot:item.quantity="props">
                                <v-edit-dialog :return-value.sync="props.item.quantity" @save="changeSubtotal(props.item.big_product_id)"> 
                                    <!--@cancel="cancelEdition" @open="open" @close="close" -->
                                    <div class="primary--text" style="font-weight: 700;">{{ props.item.quantity }}</div>
                                    <template v-slot:input>
                                        <v-text-field v-model="props.item.quantity" label="Cantidad"></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </template>

                            <template v-slot:item.amount="props">
                                <v-edit-dialog :return-value.sync="props.item.amount" @save="changeSubtotal(props.item.big_product_id)"> 
                                    <!--@cancel="cancelEdition" @open="open" @close="close" -->
                                    <div class="primary--text" style="font-weight: 700;">{{ props.item.amount }}</div>
                                    <template v-slot:input>
                                        <v-text-field v-model="props.item.amount" label="Costo"></v-text-field>
                                    </template>
                                </v-edit-dialog>
                            </template>

                            <template v-slot:item.actions="{ item }">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-btn x-small class="mx-0" icon @click="removeProduct(item.big_product_id)">
                                            <v-icon color="secondary" v-on="on">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Borrar</span>
                                </v-tooltip>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'proveedor-form',
    props: ['rules', 'values', 'edition'],
    data(){
        return {
            new_product: false,
            product_form: {
                name: '',
                description: '',
                price: '',
            },
            product_id: '',
            loadingClients: false,
            loadingBuildings: false,
            loadingProducts: false,
            products: [],
            items: {
                clients: [],
                buildings: [],
                products: []
            },
            dateProps: {
                visible: false,
                date: '',
                width: '300px',
                label: 'Fecha',
                icon: 'mdi-calendar',
                btn: {
                    cancelColor: '',
                    okColor: 'primary'
                }
            },
            tableProps: {
                headers: [
                    {
                        text: 'Producto',
                        align: 'left',
                        value: 'product',
                        width: '40%',
                        class: 'primary--text uppercase--text'
                    },
                    {
                        text: 'Cantidad',
                        align: 'center',
                        value: 'quantity',
                        width: '10%',
                        class: 'primary--text uppercase--text'
                    },
                    {
                        text: 'Costo',
                        align: 'center',
                        value: 'amount',
                        width: '10%',
                        class: 'primary--text uppercase--text'
                    },
                    {
                        text: 'Subtotal',
                        align: 'center',
                        value: 'subtotal',
                        width: '10%',
                        class: 'primary--text uppercase--text'
                    },
                    {
                        text: 'Acciones',
                        align: 'right',
                        value: 'actions',
                        width: '10%',
                        class: 'primary--text uppercase--text'
                    },
                ],
                // items: []
            }
        }
    },
    mounted(){
        this.index()
    },
    methods: {
        index(){
            this.loadingClients = true
            this.$http.get(this.$store.state.apiRoute+'/getClients')
            .then((response) => { this.items.clients = response.data })
            .catch(() => { })
            .finally(() => { this.loadingClients = false })

            if(this.edition){
                this.loadingBuildings = true
                this.$http.get(this.$store.state.apiRoute+'/getBuildings')
                .then((response) => { this.items.buildings = response.data })
                .catch(() => { })
                .finally(() => { this.loadingBuildings = false })
            }
            
            this.loadingProducts = true
            this.$http.get(this.$store.state.apiRoute+'/getBigProducts')
            .then((response) => { this.items.products = response.data })
            .catch(() => { })
            .finally(() => { this.loadingProducts = false })
        },
        getProductName(id){
            var product = this.items.products.find(x => x.value === id)
            return product.text
        },
        addProducts(){
            if(this.product_id != ''){
                var validation = this.values.products.findIndex(x => x.big_product_id === this.product_id)
                if(validation < 0){
                    var product = this.items.products.find(x => x.value === this.product_id)
                    var product_t = {
                        big_product_id: this.product_id,
                        product: this.getProductName(this.product_id),
                        quantity: 1,
                        amount: product.price,
                        subtotal: product.price,
                        actions: ''
                    }
                    this.values.products.push(product_t)
                }
            }
        },
        changeSubtotal(id){
            let found = this.values.products.findIndex(element => element.big_product_id == id)            
            this.values.products[found].subtotal = parseFloat(this.values.products[found].amount) * parseFloat(this.values.products[found].quantity)
        },
        removeProduct(id){
            let found = this.values.products.findIndex(element => element.big_product_id == id)
            this.values.products.splice(found, 1)
        },
        saveProduct(){
            if(this.$refs.miniform.validate()){
                let form = this.product_form
                form.description = form.name

                this.$http.post(this.$store.state.apiRoute+'/bigProducts/store/cotizacion', form)
                .then((response) => {
                    this.values.products.push({
                        big_product_id: response.data.message.value,
                        product: response.data.message.text,
                        quantity: 1,
                        amount: response.data.message.price,
                        subtotal: response.data.message.price,
                        actions: ''
                    })

                    this.$store.state.globalSnackbarProps.visible = true
                    this.$store.state.globalSnackbarProps.text = 'Se ha registrado correctamente el producto <b></b>'
                })
                .catch((error) => {
                    var errors = []
                    switch(error.response.status){
                        case 422: 
                            Object.keys(error.response.data.errors).forEach((x) => {
                                error.response.data.errors[x].forEach((y) => {
                                    errors.push(y)
                                })
                            })
                        break;
                        case 500: 
                            errors.push('Error en el servidor. Intente de nuevo.')
                        break;
                        case 404: 
                            errors.push('No encontrado.')
                        break;
                    }
                })
                .finally(() => {
                    this.$store.state.overlay = false
                })
            }
        },
        save(){
            this.$emit('save')
        }
    }
}
</script>

<style>
.clicked{
    -webkit-box-shadow: inset 1px 1px 5px rgba(0,0,0,.2) !important;
    -moz-box-shadow: inset 1px 1px 5px rgba(0,0,0,.2) !important;
    box-shadow: inset 1px 1px 5px rgba(0,0,0,.2) !important;
}
</style>