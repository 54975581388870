import Vue from 'vue'

//  Layouts
Vue.component('loading', require('./../views/Layouts/Loading.vue').default);
Vue.component('dashboard', require('./../views/Layouts/Dashboard.vue').default);
Vue.component('login', require('./../views/Layouts/Login.vue').default);


Vue.component('datatable', require('./Datatable.vue').default);
Vue.component('snackbar', require('./Snackbar.vue').default);
Vue.component('global-snackbar', require('./GlobalSnackbar.vue').default);
Vue.component('loading-overlay', require('./LoadingOverlay.vue').default);
Vue.component('choose-modal', require('./../components/ChooseModal.vue').default);
Vue.component('modal', require('./../components/Modal.vue').default);
Vue.component('country-autocomplete', require('./../components/CountryAutocomplete.vue').default);
Vue.component('details-card', require('./../components/DetailsCard.vue').default);
Vue.component('datepicker-dialog', require('./../components/DatepickerDialog.vue').default);
Vue.component('contentCard', require("./ContentCard.vue").default);
Vue.component('loadingPage', require("./LoadingPage.vue").default);