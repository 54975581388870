<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
            <div>
                <v-row justify="center">
                    <v-col class="text-center">
                        <div class="text-uppercase font-weight-bold primary--text text-h5">
                            Detalles de la cotización
                        </div>
                    </v-col>
                </v-row>

                <v-card-text>
                    <v-row justify="center" class="px-4">
                        <v-col cols="12" md="8" lg="8">
                            <v-row>
                                <v-col>
                                    <span class="font-weight-bold primary--text">Folio: </span>{{ id }}
                                </v-col>
                                <v-col class="text-right">
                                    <span class="font-weight-bold primary--text">Fecha: </span>{{ fecha }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6" :class="`${i%2==0 ? '':'text-right'}`" v-for="(item, i) in detailsItems[0].items" :key="i"><span class="font-weight-bold primary--text">{{ item.concepto }}: </span> {{ item.valor }}</v-col>
                            </v-row>
                            <!-- <details-card :detailsItems="detailsItems"></details-card> -->
                        </v-col>
                    </v-row>
                    <v-row justify="center" class="px-4">
                        <v-col cols="12" md="8" lg="8" >
                            <span class="font-weight-bold primary--text">Condiciones: </span><br>
                            <span>{{ conditions }}</span>
                        </v-col>
                    </v-row>
                    <v-row justify="center" no-gutters>
                        <v-col cols="12" md="8" lg="8">
                            <v-simple-table fixed-header min-height="300px">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left primary--text">PRODUCTO</th>
                                            <th class="text-center primary--text">CANTIDAD</th>
                                            <th class="text-right primary--text">COSTO</th>
                                            <th class="text-right primary--text">SUBTOTAL</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(product, i) in products" :key="i" class="text-center">
                                            <td class="text-left">{{ product.product }}</td>
                                            <td>{{ product.quantity }}</td>
                                            <td class="text-right">${{ numberFormat.format(product.amount) }}</td>
                                            <td class="text-right">${{ numberFormat.format(product.total) }}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th class="text-left primary--text" colspan="3">TOTAL</th>
                                            <th class="text-right primary--text subtitle-2">${{ total }}</th>
                                        </tr>
                                    </tfoot>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </div>
               
            
            <v-row justify="center" align="end">
                <v-col cols="12" md="8" lg="8" class="text-right">
                    <v-btn color="secondary" class="full-width-btn text-base-color" tile :to="{ name: 'Cotizaciones' }">Regresar</v-btn>
                </v-col>
            </v-row>
        </template>
    </contentCard>
</template>

<script>
export default {
    computed: {
        total(){
            return parseFloat(this.products.reduce(function(a, b){
                return a + b.total;
            }, 0)).toFixed(2);
        }
    },
    data(){
        return {
            loading:false,
            id: this.$route.params.id,
            conditions: '',
            detailsItems: [
                {
                    title: null, 
                    items: [
                        { concepto: 'Cliente', index: 'client', valor: null },
                        { concepto: 'Razón Social', index: 'business_name', valor: null },
                        { concepto: 'R.F.C.', index: 'rfc', valor: null },
                        { concepto: 'Obra', index: 'building', valor: null },
                        // { concepto: 'Fecha', index: 'date', valor: null },
                    ],
                }
            ],
            products: [],
            numberFormat: new Intl.NumberFormat('en-us', {minimumFractionDigits: 2}),
        }
    },
    mounted(){
        this.index()
    },
    methods: {
        index(){
            this.loading = true
            this.$http.get(this.$store.state.apiRoute+'/quotes/'+this.id+'/edit')
            .then((response) => {
                this.detailsItems.forEach((x) => {
                    x.items.forEach((y) => {
                        y.valor = response.data.data[y.index] === null ? '' : response.data.data[y.index]
                    })
                })

                this.fecha = response.data.data.date
                this.conditions = response.data.data.conditions

                this.products = response.data.variants
            })
            .catch(error => {
                error
            })
            .finally(() => {
                this.loading = false
            })
        }
    }
}
</script>

<style>
</style>