<template>
	<div class="app-container">
        <v-container fluid>
			<sidemenu></sidemenu>
			<navbar></navbar>
			<v-main class="mx-0 mx-lg-2">
                <div class="main-container" >
                    <router-view></router-view>
                </div>
                <app-footer></app-footer>
			</v-main>
        </v-container>
	</div>
</template>
<script lang="js">
/* eslint-disable */
	import Navbar from '@/components/Navbar.vue'
	import Sidemenu from '@/components/Sidemenu.vue'
    import AppFooter from "@/components/AppFooter.vue";
	export default {
		name: 'Dashboard',
		components: {
			Navbar,
			Sidemenu,
            AppFooter
		},
        mounted(){
            this.initSW()
        },
        methods: {
            initSW() {
                if (!("serviceWorker" in navigator)) {
                    console.log("service worker isn't supported");
                    //service worker isn't supported
                    return;
                }

                //don't use it here if you use service worker
                //for other stuff.
                if (!("PushManager" in window)) {
                    console.log("push isn't supported");
                    //push isn't supported
                    return;
                }

                //register the service worker
                navigator.serviceWorker.register('sw.js')
                .then((registration) => {
                    console.log('serviceWorker installed!')
                    registration.update();
                    this.initPush();
                })
                .catch((err) => {
                    console.log(err)
                });
            },
            initPush() {
                if (!navigator.serviceWorker.ready) {
                    console.log("serviceWorker.ready")
                    return;
                }

                new Promise(function (resolve, reject) {
                    const permissionResult = Notification.requestPermission(function (result) {
                        console.log(result, "RESULT");
                        resolve(result);
                       
                    });
                    console.log(permissionResult, "permisionRESULT value")
                    if (permissionResult) {
                        console.log(permissionResult, "permisionRESULT");
                        permissionResult.then(resolve, reject);
                    }
                })
                .then((permissionResult) => {
                    console.log("then > ", permissionResult)
                    if (permissionResult !== 'granted') {
                        throw new Error('We weren\'t granted permission.');
                    }
                    this.subscribeUser();
                });
            },
            subscribeUser() {
                console.log("subscribeUser called")
                navigator.serviceWorker.ready
                .then((registration) => {
                    const subscribeOptions = {
                        userVisibleOnly: true,
                        applicationServerKey: this.urlBase64ToUint8Array(process.env.VUE_APP_VAPID_PUBLIC_KEY)
                    };
                    console.log(registration, "options")
                    return registration.pushManager.subscribe(subscribeOptions);
                })
                .then((pushSubscription) => {
                    console.log('Received PushSubscription: ', JSON.stringify(pushSubscription));
                    this.storePushSubscription(pushSubscription);
                });
            },
            urlBase64ToUint8Array(base64String) {
                var padding = '='.repeat((4 - base64String.length % 4) % 4);
                
                var base64 = (base64String + padding)
                    .replace(/\-/g, '+')
                    .replace(/_/g, '/');

                var rawData = window.atob(base64);
                var outputArray = new Uint8Array(rawData.length);

                for (var i = 0; i < rawData.length; ++i) {
                    outputArray[i] = rawData.charCodeAt(i);
                }
                return outputArray;
            },
            storePushSubscription(pushSubscription) {
                console.log("store called")
                this.$http.post(this.$store.state.apiRoute+'/push', pushSubscription )
                    .then((res) => {
                        console.log("success stored", res.data);
                    })
                    
                    .catch((err) => {
                        console.log(err)
                    }
                );

            
            }
        }
	}
</script>


<style lang="scss">
	.main-container{
		margin: 0 12px;
		// display: flex;
		// flex-direction: column;
		// //height: 89vh;
		// min-height: 89vh;
		// justify-content: space-between;
		min-height: calc(100vh - 120px);
	}

	.app-container{
		background-color: var(--v-background-base);
	}

	
</style>