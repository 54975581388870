<template>
    <div>
        <v-row class="py-0" align="center" justify="center">
            <v-col cols="12">
                <label for="">Tipo de cuenta *</label>
            </v-col>
            <v-col class="py-0">
                    <!-- :error="error" -->
                <v-radio-group mandatory row color="primary" v-model="values.type" dense hide-details class="mt-0 pt-0">
                    <v-radio label="Crédito" value="Crédito"></v-radio>
                    <v-radio label="Débito" value="Débito"></v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-autocomplete :items="items.banks" label="Bancos *" v-model="values.bank_id " :rules="rules.bank_id" :loading="loadingBanks" append-icon="mdi-chevron-down"></v-autocomplete>
            </v-col>
        </v-row>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-text-field label="Alias " v-model="values.alias" :rules="rules.alias"></v-text-field>
            </v-col>
        </v-row>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-text-field label="Número de cuenta *" v-model="values.account_number " :rules="rules.account_number"></v-text-field>
            </v-col>
        </v-row>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <v-text-field label="Saldo inicial *" v-model="values.initial_balance " :rules="rules.initial_balance"></v-text-field>
            </v-col>
        </v-row>
        <v-row class="py-0" align="center" justify="center">
            <v-col class="py-0">
                <datepicker-dialog :picker="dateProps" :dateVal="values.initial_balance_date" @setDate="v => values.initial_balance_date = v" :rules="rules.initial_balance_date"></datepicker-dialog>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    props: ['rules', 'values'],
    data(){
        return {
            dateProps: {
                visible: false,
                date: '',
                width: '300px',
                label: 'Fecha de saldo inicial *',
                icon: 'mdi-calendar',
                btn: {
                    cancelColor: '',
                    okColor: 'primary'
                }
            },
            loadingBanks: false,
            items: {
                banks: []
            }
        }
    },
    mounted(){
        this.index()
    },
    methods: {
        index(){
            this.loadingBanks = true
            this.$http.get(this.$store.state.apiRoute+'/catalogs/getBanks')
            .then((response) => { this.items.banks = response.data })
            .catch(() => { })
            .finally(() => { this.loadingBanks = false })
        }
    }
}
</script>

<style>

</style>