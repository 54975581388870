<template>
    <contentCard :loading="loading">
        <template slot="cardContent">

            <div>
                <v-row justify="center">
                    <v-col class="text-center">
                        <div class="text-uppercase font-weight-bold primary--text text-h5">
                            Editar concepto
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="5">
                        <v-form ref="form" @submit.prevent="save">
                            <coneptos-form ref="conceptoForm" :values="form" @save="save" :edition="true"></coneptos-form>
                        </v-form>
                    </v-col>
                </v-row>

            </div>
            <v-row justify="center" align="end">
				<v-col cols="12" md="5" class="text-right">
					<v-btn class="full-width-btn" tile text color="primary" :to="{ name: 'Conceptos' }">Cancelar</v-btn>
					<v-btn tile elevation="0" class="ml-md-2 ml-0 mt-md-0 mt-3 btn-yellow full-width-btn text-base-color" @click="save">Guardar</v-btn>
				</v-col>
			</v-row>
            <snackbar :props="snackbarProps"></snackbar>
        </template>
    </contentCard>
</template>

<script>
import conceptoForm from "./Form.vue";
export default {
    components: {
        'coneptos-form': conceptoForm,
    },
	data(){
		return {
            id: this.$route.params.id,
            loading: false,
            form: {
                category: "",
                description: "",
                year: "",
                catalog_id:"",
            },
            
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        this.index();
    },
	methods: {
		validate () {
			return this.$refs.form.validate();
		},
        index(){
			this.loading = true
            this.$http.get(`${this.$store.state.apiRoute}/concepts/${this.id}/edit`)
            .then((response) => {
                this.form = {
					category: response.data.category,
                    description: response.data.description,
                    year: response.data.year,
                    catalog_id: response.data.catalog.slug,
				}
            })
            .catch(error => {
                error
            })
            .finally(() => {
                this.loading = false
            })
        },
        save(){
            
            if(this.validate()){               
                this.$store.state.overlay = true
                let form = JSON.parse(JSON.stringify(this.form))
               

                this.$http.put(`${this.$store.state.apiRoute}/concepts/${this.id}/update`, form)
                .then((response) => {
                    response.data

                    this.$store.state.globalSnackbarProps.visible = true;
                    this.$store.state.globalSnackbarProps.text = 'Se ha Actualizado correctamente';
                    this.$router.push({name: 'Conceptos'})
                })
                .catch((error) => {
                    var errors = []
                    switch(error.response.status){
                        case 422: 
                            Object.keys(error.response.data.errors).forEach((x) => {
                                error.response.data.errors[x].forEach((y) => {
                                    errors.push(y)
                                })
                            })
                        break;
                        case 500: 
                            errors.push('Error en el servidor. Intente de nuevo.')
                        break;
                        case 404: 
                            errors.push('No encontrado.')
                        break;
                    }

                    this.snackbarProps = {
                        visible: true,
                        color: 'error',
                        timeout: 10000,
                        text: errors.join('.<br>'),
                    }
                })
                .finally(() => {
                    this.$store.state.overlay = false
                })
            }else{
                // this.$refs.conceptoForm.moveStep(1)
                this.snackbarProps = {
                    visible: true,
                    color: 'error',
                    timeout: 2000,
                    text: 'Formulario incompleto',
                }

                this.$store.state.overlay = false
            }
            // eslint-disable-next-line no-console
        }
    },
}
</script>

<style>

</style>