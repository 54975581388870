
export default class NotificationManager{

    //appTokenName = "eme_merca_notification_id";

    /**
     * Returns sessionStorage notification id
     *  
     */
    static getLastNotification()
    {
       return sessionStorage.getItem("eme_merca_notification_id");
       
    }

    /**
     * 
     * @param {string} id 
     */
    static setLastNotification(id)
    {   
        sessionStorage.setItem("eme_merca_notification_id", id);
    }

    /**
     * Delete app token
     */
    static removeLastNotification()
    {
        sessionStorage.removeItem("eme_merca_notification_id");
    }
    
}
